<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:actions="actions"
			:externalFilter="filterByUserBerths"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<movementmodals :modelName="modelName"></movementmodals>
	</div>
</template>

<script>
import MovementActions from './MovementActions.js';
import MovementModals from './MovementModals';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'movementgrid',
	components: { movementmodals: MovementModals },
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'movement',
			userBerthIds: [],
			modelColumnDefs: {
				movementstatus: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				}
			},
			actions: MovementActions.gridactions
		};
	},
	mounted() {
		this.userBerthIds = this.$store.getters.getUserBerthIds;
	},
	computed: {
		filterByUserBerths() {
			return {
				groups: [],
				groupOp: 'or',
				rules: [
					{ field: 'locationfrom', op: 'in', data: this.userBerthIds },
					{ field: 'locationto', op: 'in', data: this.userBerthIds },
					{ field: 'berthfrom', op: 'in', data: this.userBerthIds },
					{ field: 'berthto', op: 'in', data: this.userBerthIds }
				]
			};
		}
	}
};
</script>
